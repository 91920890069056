@import "variables";

.swiper-button-next,
.swiper-button-prev {
  color: black;
  opacity: 0.8;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-disabled {
  opacity: 0;
}

.swiper-button-custom {
  &--prev,
  &--next {
    cursor: pointer;
    height: 50px;
    min-width: 24px;
    overflow: hidden;
    &:after {
      content: " ";
      display: inline-block;
      margin-top: 10px;
      border-right: 3px solid #222222;
      border-bottom: 3px solid #222222;
      width: 30px;
      height: 30px;
    }
  }

  &--next {
    margin-left: 1px;
    &:after {
      margin-left: -12px;
      transform: rotate(-45deg);
    }
  }

  &--prev {
    margin-right: 1px;
    &:after {
      margin-left: 7px;
      transform: rotate(135deg);
    }
  }
}
