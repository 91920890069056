@import "variables";

.overlay {
  background-color: rgba($primary-color, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: $padding-base * 1.5;
  overflow: auto;
  z-index: $z-index-modal;

  @include not-mobile {
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}

.modal {
  position: relative;
  min-width: 600px;
  padding: $padding-base * 2;
  background: white;
  border-radius: 0;
  outline: none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  @include mobile {
    width: 100%;
    min-width: 0;
    margin: auto;
  }
}

.close {
  position: absolute;
  right: 5px;
  top: 0;
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: 0;
  opacity: 0.75;
  color: #85888a;
  font-size: 22px;
  font-weight: 700;
  &:hover {
    opacity: 1;
  }
}
