$pepsi-blue: #003069;
$lightest-blue: #d1eeff;
$dark-gray: #222222;
$gray: #999999;
$lightest-gray: #bcbcbc;
$light-gray: #f6f6f6;
$light-green: #00883f;
$light-orange: #e07647;
$red: #a62635;

$gatorade-orange: #fa5000;
$black: #000000;
$white: #ffffff;
$medium-dark-gray: #666666;
