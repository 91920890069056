@import "variables";
@import "colors";

// Hide cart if user is logged out
.header-logged-out {
  .header-shopping-cart {
    display: none;
  }
}

.header {
  #prefix__account-icon {
    display: none;
  }

  .header__main-section .header-logo .header-logo__img {
    max-height: 40px;
    max-width: 160px;
  }

  .header-nav-item:hover {
    color: #fff;
    background-color: #000;
  }

  .header-end-section {
    @include laptop {
      // calc for IE, since just width: 100% by itself
      // pushes Shop & Account links into the logo
      width: calc(100% - 300px);
      justify-content: space-between;
    }
  }
}

// https://medium.com/@brunn/detecting-autofilled-fields-in-javascript-aed598d25da7
// Expose a hook for JavaScript when autofill is shown
// JavaScript can capture 'animationstart' events

@keyframes onAutofillStart {
  from {
    /**/
  }
  to {
    /**/
  }
}
@keyframes onAutofillCancel {
  from {
    /**/
  }
  to {
    /**/
  }
}
// Here we are selecting the previous sibling of the input field this way
// https://stackoverflow.com/a/36118012/10697464
input.input-field:-webkit-autofill {
  animation-name: onAutofillStart;

  & + span.input-field-label {
    transform: scale(0.8) translate(0, -25px);
    transition: transform 0.2s, color 0.2s;
  }

  &:last-child {
    order: -1;
  }
}
input.input-field:not(:-webkit-autofill) {
  animation-name: onAutofillCancel;
}

input#variant,
input#quantity {
  // on IE 11, an input with readonly attribute still has a cursor visible
  // setting width here on the inputs so that it's not visible B2B-551
  width: 0px;
}
