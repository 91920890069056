@import "variables";

body {
  margin: 0;
  font-family: $font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: $text-color;
}

a {
  text-decoration: none;
  color: $text-color;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/**
 * We need this so when you #-navigate to the ID it is visible
 * Isn't working if element with ID has top margin.
**/
*[id]:before {
  &:not(#consent_blackbar) {
    display: block;
    content: " ";
    visibility: hidden;
    margin-top: -1 * $header-height-mobile;
    height: $header-height-mobile;
    @include not-mobile {
      margin-top: -1 * $header-height;
      height: $header-height;
    }
  }
}

.with-promo {
  *[id]:before {
    &:not(#consent_blackbar) {
      margin-top: -1 * ($header-height-mobile + $header-promo-height);
      height: ($header-height-mobile + $header-promo-height);
      @include not-mobile {
        margin-top: -1 * ($header-height + $header-promo-height);
        height: ($header-height + $header-promo-height);
      }
    }
  }
}

.page-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .page-wrapper {
    overflow: hidden;
    flex: 1 0 auto;
    margin-top: $header-height;

    &.with-promo {
      margin-top: $header-height-mobile + $header-promo-height;
      @include laptop {
        margin-top: $header-height + $header-promo-height;
      }
    }
  }

  .page-footer {
    flex-shrink: 0;
  }
}
